import axios from 'axios';
import { LOGGER_SERVER } from 'src/constants/API';

const errorLoggingApiInstance = axios.create({
  baseURL: LOGGER_SERVER,
  timeout: 10000,
});

export function loggingError(message: string, etc?: string) {
  return errorLoggingApiInstance
    .post('', {
      appName: 'exam-front-dev',
      env: 'dev',
      message,
      userAgent: navigator.userAgent,
      path: window.location.href,
      userdata: 'none',
    })
    .catch((err) => {
      console.warn('Failed to send logging server:', err);
    });
}
